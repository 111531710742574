import React from 'react';
import TextField from '@material-ui/core/TextField';
import {
  Field,
} from 'formik';
import _get from 'lodash.get';

export default ({ name, helperText, error, ...rest }) => {
  return (
    <Field
      name={name}
    >
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
        meta,
      }) => {
        const fieldTouched = _get(touched, name);
        const fieldError = _get(errors, name);
        return (
          <>
            <TextField
              {...field}
              {...rest}
              InputLabelProps={{
                shrink: true,
              }}
              error={error || (!!fieldTouched && !!fieldError)}
              helperText={(!!fieldTouched && fieldError) || helperText}
            />
          </>
        );
      }}
    </Field>
  );
};